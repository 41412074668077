import { render, staticRenderFns } from "./SupportTicketDetails.vue?vue&type=template&id=1ac496d8&scoped=true&"
import script from "./SupportTicketDetails.vue?vue&type=script&lang=js&"
export * from "./SupportTicketDetails.vue?vue&type=script&lang=js&"
import style0 from "./SupportTicketDetails.vue?vue&type=style&index=0&id=1ac496d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ac496d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VFlex,VForm,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSelect})

export const USER_MESSAGE                   = 'User is required';
export const FIRST_NAME_MESSAGE             = 'First name is required';
export const LAST_NAME_MESSAGE              = 'Last name is required';
export const EMAIL_MESSAGE                  = 'Email is not valid';
export const PHONE_MESSAGE                  = 'Phone is not valid';
export const PASSWORD_MESSAGE               = "Password is required and must be minimum 8 characters, at least one lowercase letter, uppercase letter and one number";
export const PASSWORD_CONFIRMATION_MESSAGE  = "Password Confirmation is Required and must match Password";
export const NAME_MESSAGE                   = "Name is not valid";
export const DESCRIPTION_MESSAGE            = "Description is not valid";
export const FILE_MESSAGE                   = "File is required";
export const VIDEO_MESSAGE                  = "Video is required";
export const VIDEO_FILE_SIZE_MESSAGE        = "Video file has maximum size of 1000MB";
export const VIDEO_FILE_TYPES_MESSAGE       = "Video file type must be an MP4";
export const CATEGORY_MESSAGE               = "Category is required";
export const COURSE_MESSAGE                 = "Course is required";
export const COURSE_AREA_MESSAGE            = "Course Area is required";
export const TRAILER_FILE_SIZE_MESSAGE      = "Trailer file has maximum size of 10MB";
export const TRAILER_FILE_TYPES_MESSAGE     = "Trailer file type must be an MP4";
export const TRAILER_MESSAGE                = "Trailer is required";
export const TRAINER_MESSAGE                = "Trainer is required";
export const IMAGE_FILE_TYPES_MESSAGE       = "Image file type must be jpeg/png/webp";
export const IMAGE_FILE_SIZE_MESSAGE        = "Image file has maximum size of 5MB";
export const IMAGE_MESSAGE                  = "Image is required";
export const PRICE_MESSAGE                  = "Price must be a number";
export const COUPON_TYPE_MESSAGE            = "Coupon type is invalid";
export const DISCOUNT_MESSAGE               = "Discount must be a number";
export const FEE_MESSAGE                    = "Fee must be a number between 1-100";
export const EXPIRATION_MESSAGE             = "Expiration date is required";
export const COMMENT_MESSAGE                = "Comment is required";
export const TITLE_MESSAGE                  = "Trainer title is required";
export const TRAINING_OPTION_MESSAGE        = "Training Option is required";
export const TRAINING_OPTION_VALUE_MESSAGE  = "Training Option value is required";